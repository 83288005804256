.Cell-dayView {
    display: none;
}

.apt-custom div:first-child {
    display: none;
}

.legend-key {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin: 10px 5px;
}

.employment-grid-item {
    box-sizing: border-box;
    border: 1px solid lightgray;
    border-top: none;
}

.center-text {
    display: flex;
    align-items: center;
    justify-content: center;
}

.grid-item {
    border-collapse: collapse;
    border: 1px solid lightgray;
    /* border-right: none; */
    /* box-shadow: 1px 0 0 0 #888, 0 1px 0 0 #888, 1px 1px 0 0 #888, 1px 0 0 0 #888 inset, 0 1px 0 0 #888 inset; */
}

/* .grid-item:last-child {
    border-right: 1px solid lightgray;
} */
